import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStyles } from "../style";
import queryString from "query-string";
import IndexPage from "./index-1";
import IndexPage2 from "./voc-cao";
import {graphql} from 'gatsby';

const Index: React.FC = ({ location }: any) => {
  const { company, soNum, phoneNum } = queryString.parse(location?.search);
  const classes = useStyles();
  useEffect(() => {
    const handleDOMLoaded = () => {
      if (company === `cao`) {
        document.documentElement.style.backgroundColor = `#fff`;
        document.body.style.backgroundColor = `#fff`;
      } else {
        document.documentElement.style.backgroundColor = `#061f35`;
        document.body.style.backgroundColor = `#061f35`;
      }
    };

    if (document.readyState === `complete` || document.readyState === `interactive`) {
      handleDOMLoaded();
    } else {
      document.addEventListener(`DOMContentLoaded`, handleDOMLoaded);
    }

    return () => {
      document.removeEventListener(`DOMContentLoaded`, handleDOMLoaded);
    };
  }, [location]);

  return (
    <div>
      {soNum === undefined || phoneNum === undefined ? (
        <Backdrop className={classes.backdrop} open={company === undefined && true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : company === `cao` ? (
        <>
          <IndexPage2 phoneNum={phoneNum as any} soNum={soNum as any} company="cao" />
        </>
      ) : (
        <IndexPage soNum={soNum as any} phoneNum={phoneNum as any} company="pnj" />
      )}
    </div>
  );
};

export default Index;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;